import { useState, useEffect } from "react";
import { Form } from "react-formio";
import "./stylesF.css";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ToastContainer, toast } from "react-toastify";
import RestoreIcon from "@mui/icons-material/Restore";
import "react-toastify/dist/ReactToastify.css";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { getLocalTime } from "../../utils/dateTimeByTZ";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {};

interface patchData {
  istrash: boolean;
}

const TrashForms = (props: Props) => {
  const BASE_URL = process.env.REACT_APP_API_KEY;
  const [formsData, setFormsData] = useState([]);
  const [openform, setOpenform] = useState(false);
  const [editform, setEditform] = useState(false);
  const [formData, setFormData] = useState({ components: [] });
  const [formName, setFormName] = useState("");
  const [formType, setFormType] = useState("");
  const [currentFormId, setCurrentFormId] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDeleteClick = (id: any) => {
    setSelectedId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const fetchFormsData = async () => {
    setLoading(true);
    const token = localStorage.getItem("authtoken") || "";
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const systemNumber = localStorage.getItem("systemNumber");
      const response = await axios.get(
        `${BASE_URL}/form/getForms/${systemNumber}?page=1&istrash=true`,
        config
      );

      const filteredForms = response.data.allForms.filter(
        (item: { istrash: any }) => item.istrash
      );
      setFormsData(filteredForms);
    } catch (error) {
      console.error("Error fetching forms:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setOpenform(false);
    fetchFormsData();
  }, []);

  const dataF = formsData.map((form: any, index: any) => ({
    id: form._id,
    formName: form.name,
    date: form.date,
  }));

  const showToast = (message: string, type: "success" | "error") => {
    toast[type](message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  async function formFun(formId: number, action: "open" | "restore") {
    const token = localStorage.getItem("authtoken") || "";
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const id = formId;

      if (action === "open") {
        const formResponse = await axios.get(`${BASE_URL}/form/${id}`, config);

        setFormData(formResponse?.data.form.values);
        setFormName(formResponse?.data.form.name);
        setFormType(formResponse?.data.form.type);
        setCurrentFormId(id);
        if (action === "open") {
          setOpenform(true);
          setEditform(false);
        } else if (action === "edit") {
          setOpenform(false);
          setEditform(true);
        }
      } else if (action === "restore") {
        setOpenDialog(false);

        const data: patchData = {
          istrash: false,
        };
        const deleteResponse = await axios.patch(
          `${BASE_URL}/form/${selectedId}`,
          data,
          config
        );

        if (deleteResponse.data.success) {
          showToast("Form Restored Successfully", "success");
          fetchFormsData();
        } else {
          showToast("Failed to delete form", "error");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setOpenform(false);
      setEditform(false);
    }
  }

  const form = {
    components: formData,
  };

  function closeform() {
    setOpenform(false);
    setEditform(false);
  }

  const redirectToCreateForm = () => {
    window.location.pathname = "/form/createform";
  };

  return (
    <>
      <ToastContainer />
      {!openform && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <h2 style={{ fontSize: "28px", fontWeight: "bold" }}> FORMS </h2>
            <hr></hr>
            <div>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress sx={{ color: "#0e1937 !important" }} />
                </div>
              ) : (
                <>
                  {dataF.length === 0 ? (
                    <div style={{ textAlign: "center" }}>
                      <p>No forms in Trash.</p>
                      <button
                        onClick={redirectToCreateForm}
                        style={{
                          backgroundColor: "#0e1937",
                          color: "white",
                        }}
                      >
                        Create Form
                      </button>
                    </div>
                  ) : (
                    <div className="list-container">
                      {dataF.map((item) => (
                        <div
                          className="card"
                          key={item.id}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <h2 style={{ fontWeight: "bold" }}>
                              {item.formName}
                            </h2>
                            <p>{getLocalTime(item.date)}</p>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="muiButton"
                              onClick={() => formFun(item.id, "open")}
                              style={{
                                backgroundColor: "#0e1937",
                              }}
                            >
                              Open
                            </Button>
                            <Button
                              className="muiButton"
                              style={{ color: "red" }}
                              onClick={() => handleDeleteClick(item.id)}
                            >
                              <RestoreIcon />
                            </Button>
                            <Dialog
                              open={openDialog}
                              onClose={handleCloseDialog}
                              sx={{
                                "& .MuiBackdrop-root": {
                                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                                },
                              }}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                {"Restore Confirmation"}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  Are you sure you want to restore Form?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  variant="contained"
                                  onClick={handleCloseDialog}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => formFun(item.id, "restore")}
                                >
                                  Yes
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {openform && (
        <>
          <div style={{ paddingBottom: "20px" }}>
            <Button
              className="muiButton"
              sx={{ color: "#0e1937 !important" }}
              onClick={() => {
                closeform();
              }}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
            <div style={{ textAlign: "center" }}>
              <TextField
                disabled
                type="text"
                value={formName}
                label="Enter Form Name"
                className="formNameInput"
                style={{ marginRight: "40px" }}
              />
              <TextField
                disabled
                value={formType}
                sx={{ width: 300 }}
                placeholder="Form type"
              />
            </div>
          </div>
          <Form form={form} />
        </>
      )}
    </>
  );
};

export default TrashForms;
