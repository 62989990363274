import { getLocalTime } from "./dateTimeByTZ";
import title_logo from "../assets/images/logo.png";

let rowCount = 0;

const DynamicForm = (
  ParentFormJSON,
  formData,
  updatedValues,
  actionRequired
) => {
  const renderFormFields = (fields, formData) => {
    return fields
      ?.map((field) => {
        // console.log(fields.type)

        const shouldShowField = evaluateFieldConditions(field, formData);
        // if (shouldShowField) {
        //   rowCount = rowCount + 4
        // }
        if (!shouldShowField) {
          return "";
        } else if (field?.type === "fieldset") {
          let divData = "";
          if (field?.legend) {
            if (rowCount === 12) {
              rowCount = 6;
            } else {
              rowCount = rowCount + 6;
              let colCount = "col-" + (12 - rowCount + 6);
              divData = `<div class=${colCount}></div>`;
            }
          }
          return (
            (field?.legend
              ? divData +
              `<div class="col-12"><p><b style="display: ${shouldShowField ? "block" : "none"
              }">${field.legend}</b></p></div>`
              : "") + renderFormFields(field?.components, formData)
          );
        } else {
          if (rowCount === 12) {
            rowCount = 6;
          } else {
            rowCount = rowCount + 6;
          }
          if (field.type === "signature" || field.type === "file") {
            return renderFieldComponent(field);
          } else {
            return `<div class="col-6">` + renderFieldComponent(field) + `</div>`;
          }
        }
      })
      .join(""); // Join the array of strings into a single string without commas;
  };

  const renderFieldComponent = (field) => {
    if (field.type === "file") {
      const style =
        "max-width: 100%; max-height: 150px;object-fit: contain; margin:10px"; // Adjust as needed
      const mediaData = formData[field.key];

      // Check if mediaData is undefined or an empty array
      if (!mediaData || mediaData.length === 0) {
        return "";
      }

      // For each image, create an img element
      const images = mediaData
        ?.map((media, index) => {
          return `<img key=${index} src=${media.uri} alt="" style="${style}" />`;
        })
        .join("");

      return `<div class="col-12"><p><b>${field.label} : </b></p></div><div class="col-12"><div style="display: flex; flex-direction: row; align-items: center;">${images}</div></div>`;
    } else if (field.type === "signature") {
      const style = "max-width: 100%; max-height: 300px;object-fit: contain;"; // Adjust as needed
      const base64String = formData[field.key];
      // Check if base64String is undefined or empty
      if (!base64String) {
        return "";
      }
      // const base64String = formData["signature"];
      return `<div class="col-12"><p><b>${field.label} : </b></p></div><div class="col-12"><img src=${base64String} alt="" style="${style}" /></div>`;
    } else if (field.type === "button") {
      return "";
    } else {
      // Handle other field types and undefined values
      const fieldValue = formData[field.key] !== undefined ? formData[field.key] : "";
      if (typeof fieldValue !== "string" || fieldValue.length === 0) {
        if (fieldValue.indexOf('Attention') !== -1) {
          return `<p style='color:red'>${field.label} - ${fieldValue}</p>`;
        } else {
          return `<p>${field.label} - ${fieldValue}</p>`;
        }
      } else if (field.type === "checkbox") {
        const formattedValue = fieldValue.replace(/(?!^)([A-Z])/g, " $1");
        const value =
          formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);
        if (value) {
          return `<div style="align-items: center;font-family: 'Arial', sans-serif;
            font-size: 10px;" >
                    <span style="margin-right: 10px;" >${field.label} -</span>
                    <input style="padding-top:1px;" type="checkbox" disabled checked style="transform: scale(0.8);" />
                </div>`;
        } else {
          return `<div style="align-items: center; >
                    <span style="margin-right: 10px; >${field.label} -</span>
                    <input style="padding-top:5px;" type="checkbox" disabled style="transform: scale(0.8);" />
                </div>`;
        }
      } else {
        const formattedValue = fieldValue.replace(/(?!^)([A-Z])/g, " $1");
        const value = formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);
        if (value.indexOf('Attention') !== -1) {
          return `<p style='color:red'>${field.label} - ${value}</p>`;
        } else {
          return `<p>${field.label} - ${value}</p>`;
        }
      }
    }
  };

  const renderUpdatedFormFields = (fields) => {
    const style =
      "max-width: 100%; max-height: 200px; object-fit: contain; margin: 10px;";

    return fields
      .map((field) => {
        const imagesHtml =
          field.Images && field.Images.length > 0
            ? `<div style="display: flex; flex-wrap: wrap;">` +
            field.Images.map(
              (image) =>
                `<div style="flex: 0 1 50%; text-align: center;"><img src="${image}" alt="" style="${style}" /></div>`
            ).join("") +
            `</div>`
            : "No images";

        const signaturesHtml =
          field.Signature && field.Signature.length > 0
            ? `<div style="display: flex; flex-wrap: wrap;">` +
            field.Signature.map(
              (signature) =>
                `<div style="flex: 0 1 50%; text-align: center;"><img src="${signature}" alt="" style="${style}" /></div>`
            ).join("") +
            `</div>`
            : "No signatures";

        return `<div>
            <p><b>Comment -</b> ${field.Comment || ""}</p>
            <b>Images:</b> <br/>${imagesHtml} <br/>
            <b>Signature:</b> <br/>${signaturesHtml} <br/>
        </div>`;
      })
      .join("");
  };

  const renderActionRequiredComponent = (fields) => {
    const results = [];
    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const value = fields[key];
        const fieldName = key.charAt(0).toUpperCase() + key.slice(1); // Capitalizing the first letter
        results.push(`<p>${fieldName}: ${value}</p> \n`);
      }
    }
    return results.length !== 0
      ? `<p><b>Action Required : </b> \n ${results.join("")}</p>`
      : "";
  };

  // console.log('render', renderFormFields(ParentFormJSON, formData))
  return (
    `<div class="row">` +
    renderFormFields(ParentFormJSON, formData) +
    `</div>` +
    (updatedValues.length > 0
      ? `<div> <p><b>Additional Information:</b></p> </div>`
      : "") +
    `<div>` +
    renderUpdatedFormFields(updatedValues) +
    `</div>` +
    `<div>` +
    renderActionRequiredComponent(actionRequired) +
    `</div>` +
    `</div>`
  );
};

// **************************************************************************************
// **************************************************************************************

const evaluateTrigger = (trigger, isVisible, formData) => {
  // if no trigger exists return it's previous execution display value
  if (!trigger) return isVisible;
  const { type, simple } = trigger;
  if (type === "simple") {
    const { show, when, eq } = simple;

    const field = formData[when];
    return show === (field === eq);
  }
  // if trigger doesn't meet it's criteria return it's previous execution display value
  return isVisible;
};

const evaluateTriggers = (logics, display, formData) => {
  // default hidden value or display value
  let isVisible = display;
  // multiple triggers
  logics?.forEach((logic) => {
    const { trigger, actions } = logic;
    const shouldShow = evaluateTrigger(trigger, isVisible, formData);
    if (shouldShow) {
      actions?.forEach((action) => {
        const { name, type, property, state } = action;
        if (type === "property" && property) {
          if (property.value === "hidden") {
            if (state == true) {
              isVisible = false;
            } else {
              isVisible = true;
            }
          }
        }
      });
    }
  });
  return isVisible;
};

const evaluateFieldConditions = (field, formData) => {
  // const {conditional = {},hidden = false,logic = [],components=[]} = field;
  const { conditional, hidden, logic, components } = field;

  // 1. Return Default Value (hidden) (if no conditional and no logic exists)
  // 2. If Conditinal exists first priority will be given to show Value irrespective of hidden value(Default Value)
  // or Logic results
  // and if condition doesn't met Return Default Value(hidden value)
  // 3. Even if logic exists display priority will be given to show value in conditional or based on logic result

  // Default Value

  var display = hidden === undefined ? true : !hidden;

  // Return Default Value no conditional and no logic
  if (!conditional && !logic) {
    if (!display) {
      //if display is false delete key value in formData
      return display;
    }
    return display;
  }

  // Check conditional
  if (conditional) {
    const { show, when, eq } = conditional;
    var display_cdn = show === true ? true : false;
    const whenValue = formData[when];

    //when it meets conditional
    if (whenValue === eq) {
      if (!display_cdn) {
        //if show is false delete key value in formData
        return display_cdn;
      }
      return display_cdn;
    }

    // when it doesn't meet conditional
    else {
      display_cdn = !display_cdn; // bcoz it doen't met the conditional so reversing show value
      if (!display_cdn) {
        //if show is false delete key value in formData
        return display_cdn;
      }
      return display_cdn;
    }
  }

  // No conditional
  else if (!conditional) {
    // if logic exists
    if (logic) {
      // evaluate multiple logics(multiple triggers and multiple actions)
      const isVisible = evaluateTriggers(logic, display, formData);

      if (!isVisible) {
        return isVisible;
      }
      return isVisible;
    }
  }
};

// **************************************************************************************
// **************************************************************************************

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Function to generate HTML dynamically
export default function generatePDF(ParentFormJSON, SubmittedData) {
  const submissionDate = SubmittedData?.date || "";
  const date = getLocalTime(submissionDate);
  const latitude = SubmittedData?.lat || "";
  const longitude = SubmittedData?.long || "";
  return `
    <!DOCTYPE html>
    <html>
      <head>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
      integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">   
            <title>${SubmittedData?.title}</title>

    <style>
        .pdf {
          padding: 40px;
        }

        .pdf h6 {
            font-family: 'Arial', sans-serif;
            font-size: 10px;

        }

        .pdf p {
            font-family: 'Arial', sans-serif;
            font-size: 10px;
            margin-bottom: 0.5rem;
        }
        .checkbox {
            width: 20px;
            height: 20px;
            border: 1px solid #000;
            display: inline-block;
        }

        /* This is what simulates a checkmark icon */
        .checkbox.checked:after {
            content: '';
            display: block;
            width: 4px;
            height: 7px;

            /* "Center" the checkmark */
            position: relative;
            top: 4px;
            left: 7px;

            border: solid #000;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        div.note {
        page-break-before: avoid;
        }

    </style>
      </head>
      <body style="font-family: Arial, sans-serif;font-size: 10px;">

    <div class='pdf myContainer'>
        <div class="row mb-2">
            <div class="col-2">
                <img src=${title_logo} alt="Header Image"
                    style="width: 100%; height: auto; margin-bottom: 5px;" />
            </div>
            <div class="col-4">
                <h6><strong>S.A.F.E.App- ${SubmittedData?.title} </strong>
                    <br />Submitted by: ${capitalizeFirstLetter(
    SubmittedData?.username
  )}
                </h6>
            </div>
            <div class="col-6 text-danger text-end">
                <p>Submission Date: ${date}</p>
                <p>Latitude: ${latitude}</p>
                <p>Longitude: ${longitude}</p>
            </div>
        </div>


        <div class="form">
          ${DynamicForm(
    ParentFormJSON,
    SubmittedData?.values,
    SubmittedData?.updatedValues,
    SubmittedData?.actionRequired
  )}
        </div>
    </div>
      </body>
    </html>
  `;
}