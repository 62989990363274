// utils/coopData.js
import axios from "axios";

export const coopsData = async () => {
  const BASE_URL = process.env.REACT_APP_API_KEY;
  const token = localStorage.getItem("authtoken-federated");
  const authtoken = localStorage.getItem("authtoken");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authtoken}`,
    },
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/form/slp/system-list`,
      {
        server2Token: token,
      },
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching coop data:", error);
    throw error; // Rethrow to handle it in the calling component
  }
};
