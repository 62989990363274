import { useState, useEffect, ReactNode } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import PageWrapper from "./components/layout/PageWrapper";
import MainLayout from "./components/layout/MainLayout";
import LoginComponent from "./pages/auth/login";
import { RouteType } from "./routes/config";
import appRoutes from "./routes/appRoutes";
import SessionChecker from "./utils/sessionChecker";

const generateRoute = (routes: RouteType[]): ReactNode => {
  return routes.map((route, index) =>
    route.index ? (
      <Route
        index
        path={route.path}
        element={<PageWrapper state={route.state}>{route.element}</PageWrapper>}
        key={index}
      />
    ) : (
      <Route
        path={route.path}
        element={
          <PageWrapper state={route.child ? undefined : route.state}>
            {route.element}
          </PageWrapper>
        }
        key={index}
      >
        {route.child && generateRoute(route.child)}
      </Route>
    )
  );
};

function App() {
  const [userRole, setUserRole] = useState(localStorage.getItem("role") || "");

  useEffect(() => {
    let roleIntervalId: any;

    if (!userRole) {
      roleIntervalId = setInterval(() => {
        const currentRole = localStorage.getItem("role") || "";

        if (currentRole && currentRole !== userRole) {
          setUserRole(currentRole);
        }
      }, 1000);
    }

    return () => {
      if (roleIntervalId) {
        clearInterval(roleIntervalId);
      }
    };
  }, [userRole]);

  const routes = generateRoute(appRoutes(userRole));

  return (
    <BrowserRouter>
     <SessionChecker />  
      <Routes>
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/" element={<MainLayout />}>
          {routes}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
