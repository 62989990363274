import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField, Container, Box, Typography } from "@mui/material";
import logo from "../../assets/images/logo.png";
import "../../styles/login.css";
import backgroundImage from "../../assets/images/webBg.png";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_API_KEY;
const FORGOTPASSWORD_URL = process.env.REACT_APP_FORGOTPASSWORD_KEY;

const showToast: any = (message: string, type: "success" | "error") => {
  toast[type](message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};

const LoginComponent: React.FC = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") !== "true") {
      localStorage.clear();
      navigate("/login", { replace: true });
    } else {
      navigate("/submissions");
    }
  }, []);

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setButtonDisable(true);

    try {
      if (username && password) {
        const loginResponse = await axios.post(`${BASE_URL}/user/login`, {
          username: username.toLowerCase(),
          password: password,
        });
        if (
          loginResponse.data.message === "Login successful" &&
          loginResponse.data.role !== "user"
        ) {
          setButtonDisable(false);
          localStorage.setItem("userName", loginResponse.data.userName);
          localStorage.setItem("email", loginResponse.data.emailAddress);
          localStorage.setItem("systemNumber", loginResponse.data.systemNumber);
          localStorage.setItem(
            "authtoken-federated",
            loginResponse.data.authtoken
          );
          localStorage.setItem("authtoken", loginResponse.data.jwtToken);
          localStorage.setItem("role", loginResponse.data.role);

          const expiryTimestamp = loginResponse.data.expiry;
          localStorage.setItem("LogoutTime", expiryTimestamp);
          sessionStorage.setItem("LogoutTime", expiryTimestamp);
          localStorage.setItem("isLoggedIn", "true");
          navigate("/submissions");
        } else {
          setButtonDisable(false);
          showToast("User doesn't have admin access.", "error");
        }
      } else {
        setButtonDisable(false);
        showToast("Username and password are required.", "error"); // Set an error message for empty fields
      }
    } catch (error: unknown) {
      console.log("Axios error:", error);
      setButtonDisable(false);

      if (axios.isAxiosError(error)) {
        // Now TypeScript knows this is an Axios error
        if (error.response) {
          if (error.response.status === 401) {
            showToast("Invalid credentials. Please try again.", "error");
          } else {
            showToast("An error occurred while logging in.", "error");
          }
        } else {
          showToast("Network error or server unreachable.", "error");
        }
      } else if (error instanceof Error) {
        // Handle non-Axios errors
        console.error("Non-Axios error:", error.message);
      } else {
        // Handle cases where error is not an instance of Error
        console.error("Unexpected error:", error);
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover", // Scale the image to fit within the container
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      maxWidth={false}
    >
      <ToastContainer />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "600px",
          background: "#00000075",
          height: "600px",
          justifyContent: "center",
          borderRadius: "30px",
        }}
      >
        <img className="loginLogo" src={logo} alt="Logo" />
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 1, width: 500 }}>
          <Typography className="textFieldLabel">Username</Typography>
          <TextField
            className="textField"
            margin="normal"
            // required
            fullWidth
            disabled={buttonDisable}
            id="username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Typography className="textFieldLabel">Password</Typography>
          <TextField
            className="textField"
            margin="normal"
            // required
            fullWidth
            disabled={buttonDisable}
            name="password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputLabelProps={{
              className: "textFieldLabel",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Typography
            className="forgotPassword"
            onClick={() => window.open(FORGOTPASSWORD_URL, "_blank")}
            style={{ cursor: "pointer" }}
          >
            Forgot Password?
          </Typography>
          <Button
            type="submit"
            fullWidth
            disabled={buttonDisable}
            variant="contained"
            className="loginButton"
          >
            {buttonDisable ? "loading..." : "Login"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginComponent;
