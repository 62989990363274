// appRoutes.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import DashboardPageLayout from "../pages/dashboard/DashboardPageLayout";
import Submission from "../pages/Submissions/SubmissionList";
import Inbox from "../pages/Inbox/Inbox";
import ComponentPageLayout from "../pages/component/ComponentPageLayout";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import CreateForm from "../pages/component/CreateForm";
import InboxIcon from "@mui/icons-material/Inbox";
import EditNoteIcon from "@mui/icons-material/EditNote";
import NotesIcon from "@mui/icons-material/Notes";
import PublishedForm from "../pages/component/PublishedForm";
import LoginComponent from "../pages/auth/login";
import TrashForms from "../pages/component/TrashFroms";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Documents from "../pages/document";
import AddDocument from "../pages/document/AddDocument";
import Reports from "../pages/dashboard/Reports";
import RoleBasedRoute from "./RoleBasedRoute";
import RouteType from "./config";
import { hasAccess } from "../utils/checkRole";
import ConditionalNavigate from "../utils/conditionalNav";

const appRoutes = (userRole: string): RouteType[] => [
  {
    index: true,
    element: <ConditionalNavigate />,
    state: "home",
  },
  {
    path: "/login",
    element: <LoginComponent />,
    state: "LoginComponent",
  },
  // Conditionally render routes based on userRole
  ...(userRole
    ? [
        {
          path: "/dashboard",
          element: (
            <RoleBasedRoute
              roles={["superadmin", "admin", "manager"]}
              userRole={userRole}
            >
              <DashboardPageLayout />
            </RoleBasedRoute>
          ),
          state: "dashboard",
          sidebarProps: hasAccess(["superadmin", "admin", "manager"], userRole)
            ? {
                displayText: "Dashboard",
                icon: <DashboardOutlinedIcon />,
              }
            : undefined,
          child: [
            {
              path: "/dashboard/reports",
              element: (
                <RoleBasedRoute
                  roles={["superadmin", "admin", "manager"]}
                  userRole={userRole}
                >
                  <Reports />
                </RoleBasedRoute>
              ),
              state: "dashboard.reports",
              sidebarProps: hasAccess(
                ["superadmin", "admin", "manager"],
                userRole
              )
                ? {
                    displayText: "Reports",
                    icon: "",
                  }
                : undefined,
            },
          ],
        },
        {
          path: "/form",
          element: (
            <RoleBasedRoute roles={["superadmin"]} userRole={userRole}>
              <ComponentPageLayout />
            </RoleBasedRoute>
          ),
          state: "component",
          sidebarProps: hasAccess(["superadmin"], userRole)
            ? {
                displayText: "Forms",
                icon: <AppsOutlinedIcon />,
              }
            : undefined,
          child: [
            {
              path: "/form/publishedforms",
              element: (
                <RoleBasedRoute roles={["superadmin"]} userRole={userRole}>
                  <PublishedForm draft={false} />
                </RoleBasedRoute>
              ),
              state: "Form.PublishedForm",
              sidebarProps: hasAccess(["superadmin"], userRole)
                ? {
                    displayText: "Published Forms",
                    icon: <NotesIcon />,
                  }
                : undefined,
            },
            {
              path: "/form/draftforms",
              element: (
                <RoleBasedRoute roles={["superadmin"]} userRole={userRole}>
                  <PublishedForm draft={true} />
                </RoleBasedRoute>
              ),
              state: "Form.DraftForm",
              sidebarProps: hasAccess(["superadmin"], userRole)
                ? {
                    displayText: "Draft Forms",
                    icon: <NotesIcon />,
                  }
                : undefined,
            },
            {
              path: "/form/createform",
              element: (
                <RoleBasedRoute roles={["superadmin"]} userRole={userRole}>
                  <CreateForm />
                </RoleBasedRoute>
              ),
              state: "Form.CreateForm",
              sidebarProps: hasAccess(["superadmin"], userRole)
                ? {
                    displayText: "Create New Form",
                    icon: <EditNoteIcon />,
                  }
                : undefined,
            },
            {
              path: "/form/trashforms",
              element: (
                <RoleBasedRoute roles={["superadmin"]} userRole={userRole}>
                  <TrashForms />
                </RoleBasedRoute>
              ),
              state: "Form.TrashForm",
              sidebarProps: hasAccess(["superadmin"], userRole)
                ? {
                    displayText: "Trash Forms",
                    icon: <DeleteOutlineIcon />,
                  }
                : undefined,
            },
          ],
        },
        {
          path: "/submissions",
          element: (
            <RoleBasedRoute
              roles={["superadmin", "admin", "manager"]}
              userRole={userRole}
            >
              <Submission />
            </RoleBasedRoute>
          ),
          state: "submission",
          sidebarProps: hasAccess(["superadmin", "admin", "manager"], userRole)
            ? {
                displayText: "Submissions",
                icon: <FormatListBulletedOutlinedIcon />,
              }
            : undefined,
        },
        {
          path: "/inbox",
          element: (
            <RoleBasedRoute roles={["superadmin"]} userRole={userRole}>
              <Inbox />
            </RoleBasedRoute>
          ),
          state: "inbox",
          sidebarProps: hasAccess(["superadmin"], userRole)
            ? {
                displayText: "Inbox",
                icon: <InboxIcon />,
              }
            : undefined,
        },
        {
          path: "/documents",
          element: (
            <RoleBasedRoute roles={["superadmin", "admin"]} userRole={userRole}>
              <Documents />
            </RoleBasedRoute>
          ),
          state: "documents",
          sidebarProps: hasAccess(["superadmin", "admin"], userRole)
            ? {
                displayText: "Documents",
                icon: <ArticleOutlinedIcon />,
              }
            : undefined,
        },
        {
          path: "/documents/add",
          element: (
            <RoleBasedRoute roles={["superadmin", "admin"]} userRole={userRole}>
              <AddDocument />
            </RoleBasedRoute>
          ),
          state: "addDocuments",
        },
      ]
    : []), // If userRole is not set, render an empty array
];

export default appRoutes;
