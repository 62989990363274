import React, { useEffect, useState } from "react";
import { Formio } from "react-formio";
import axios from "axios";
import "./stylesF.css";
import "react-form-builder2/dist/app.css";
import "formiojs/dist/formio.full.css";
import FormBuilderComponent from "./FormBuilderComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextField, Autocomplete } from "@mui/material";
import { coopsData } from "../../utils/coopsData";

interface Data {
  name: string;
  type: string;
  isdraft: boolean;
  istrash: boolean;
  values: any[];
  all: boolean;
  systemNumber: any[];
}

const formOptions = [
  "Job Briefing",
  "Jobsite Observation",
  "Vehicle/Trailer Inspection",
  "Periodic Inspections",
  "Equipment Inspections",
  "General Forms",
];

export default function App() {
  const BASE_URL = process.env.REACT_APP_API_KEY;
  const [formData, setFormData] = useState({ components: [] });
  const [formName, setFormName] = useState("");
  const [formType, setFormType] = useState("");
  const [newForm, setNewForm] = useState(true);
  const [patchId, setPatchId] = useState<number | null>(null);
  const [coopList, setCoopList] = useState([]);
  const [coopData, setCoopData] = useState({ systemList: [] });
  const [all, setAll] = useState(true);
  const [systemNumbers, setSystemNumbers] = useState<string[]>([]);
  const [token, setToken] = useState("");

  const showToast = (message: string, type: "success" | "error") => {
    toast[type](message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  useEffect(() => {
    const fetchCoops = async () => {
      const coopData = await coopsData();
      setCoopData(coopData);
    };
    fetchCoops();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("authtoken") || "";
    setToken(token);
  }, []);

  const handleFormSelect = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | null
  ) => {
    setFormType(newValue || "");
  };

  const handleSubmit = async (value: any) => {
    try {
      if (formName === "") {
        showToast("Please enter the Form Name!", "error");
        return;
      } else if (formType === "") {
        showToast("Please Choose the Form Type!", "error");
        return;
      } else {
        const form = await Formio.createForm(
          document.getElementById("formio-result"),
          {
            components: formData?.components,
          }
        );

        if (all) {
          setSystemNumbers([]);
        } else {
          setSystemNumbers(coopList);
        }

        const data: Data = {
          name: formName,
          type: formType,
          isdraft: value,
          istrash: false,
          values: form.schema.components,
          all: all,
          systemNumber: all ? [] : coopList,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        let formResponse;

        formResponse = await axios.post(`${BASE_URL}/form/`, data, config);
        if (formResponse?.status === 200 && data?.isdraft === true) {
          showToast("Form Saved to Draft successfully!", "success");
          setTimeout(() => {
            window.location.href = "/form/draftforms";
          }, 3000);
        }

        if (formResponse?.status === 200 && data?.isdraft === false) {
          showToast("Form Created successfully!", "success");
          setTimeout(() => {
            window.location.href = "/form/publishedforms";
          }, 3000);
          // }
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        showToast(error?.response?.data?.error, "error");
      } else {
        showToast("Error submitting form. Please try again.", "error");
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <div className="FormContainer">
      <div
        style={{
          paddingBottom: "3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ToastContainer />
        <TextField
          type="text"
          value={formName}
          onChange={(e) => {
            setFormName(e.target.value);
          }}
          placeholder="Enter Form Name"
          label="Enter Form Name"
          className="formNameInput"
          style={{ marginRight: "40px" }}
        />
        <Autocomplete
          options={formOptions}
          value={formType}
          sx={{ width: 300 }}
          onChange={handleFormSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a Form Type"
              className="formNameInput"
            />
          )}
        />
      </div>
      <div>
        {newForm || patchId !== null ? (
          <FormBuilderComponent
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
            coopData={coopData}
            setCoopList={setCoopList}
            setAll={setAll}
            systemNumbers={systemNumbers}
          />
        ) : null}
      </div>
    </div>
  );
}
